import React from 'react';
import {Collapse, Container} from "react-bootstrap";
import {FormikProvider} from "formik";

export function NN({formik, section, componentData, getRow}) {
    return (
        <FormikProvider value={formik}>
            <Container fluid>
                {getRow(section, "externalURL", "text")}
                {getRow(section, "netTimeout", "text")}
                {getRow(section, "nnRunService", "checkbox")}
                <Collapse in={!!formik.values["nn_nnRunService"]}>
                    <Container fluid>
                        {componentData[section].map(v=> ["externalURL","netTimeout","nnRunService"].indexOf(v[0]) === -1 ? getRow(section,v[0],v[1],v[2]) : null)}
                    </Container>
                </Collapse>
            </Container>
        </FormikProvider>
    );
}
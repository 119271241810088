import React from 'react';
import {FormikProvider} from "formik";
import {Container} from "react-bootstrap";

export function StandardSection({formik, section, componentData, getRow}) {
    return (
        <FormikProvider value={formik}>
            <Container fluid>
                {componentData[section].map(v => getRow(section, v[0], v[1], v[2]))}
            </Container>
        </FormikProvider>
    );
}
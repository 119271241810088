import {getResource} from "../utils";

export default class BKService {

    _apiBase = '';

    localGetResource = async (url, method, body, token, json) => {
        if (!this._apiBase) {
            // set default
            this._apiBase = process.env.REACT_APP_BK_API_BASE;
            /*
            try {
                const response = await fetch('/config/backend_connection.json', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                });
                const backendConfig = await response.json();
                this._apiBase = backendConfig?.backendApiBase || this._apiBase;
            } catch (e) {
                console.log(e);
            }
            */
        }
        return await getResource(this._apiBase, url, method, body, token, json)
    };

    userLogin = async (login, password) => {
        return await this.localGetResource(`/user/login`, 'post', {login, password}, null);
    };

    userMe = async (token) => {
        return await this.localGetResource(`/user/me`, 'get', null, token);
    };

    userGet = async (token) => {
        return await this.localGetResource(`/user`, 'get', null, token);
    };

    userDelete = async (token, id) => {
        return await this.localGetResource(`/user/${id}`, 'delete', null, token);
    };

    userUpdate = async (token, id, body) => {
        return await this.localGetResource(`/user/${id}`, 'put', body, token);
    };

    userSet = async (token, body) => {
        return await this.localGetResource(`/user`, 'post', body, token);
    };

    setting = async (token) => {
        return await this.localGetResource(`/settings`, 'get', null, token);
    };

    getLicense = async (token) => {
        return await this.localGetResource(`/user/license`, 'get', null, token);
    };
}
import React, {useEffect, useState} from 'react';
import BKServiceComponent from "../../contexts/BackendServiceComponent";
import {Navigate} from "react-router-dom";
import {Alert, Button, Col, Container, Modal, Row, Table} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {ErrorMessage, Field, FormikProvider, useFormik} from "formik";
import * as yup from "yup";
import Spinner from "../Spinner";
import {convertPipeToNewLine, isObjectEmpty} from "../../utils";
import {TopMenu} from "../TopMenu";


export function AdminPage() {
    const {t} = useTranslation();
    const [showAdd, setShowAdd] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [user, setUser] = useState({});
    const [reload, setReload] = useState(true);
    const [listUsers, setListUsers] = useState([]);
    const [goAuth, setGoAuth] = useState(false);
    const [defaultSettings, setDefaultSettings] = useState({});


    const bkService = React.useContext(BKServiceComponent);
    const storageToken = localStorage.getItem("licenseToken");

    yup.addMethod(yup.string, "jsonString", function (errorMessage) {
        return this.test(`test-json-format`, errorMessage, function (value) {
            const { path, createError } = this;
            try {
                JSON.parse(value);
                return true;
            } catch (e) {
                createError({ path, message: errorMessage })
            }
        });
    });

    const validationSchema = yup.object({
        "login": yup.string('should be string'),
        "password": yup.string('should be string'),
        "license": yup.string('should be string').jsonString('should be JSON'),
        "system": yup.string('should be string').jsonString('should be JSON')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        enableReinitialize: true,
        initialValues: {login: '', password: '', isAdmin: 0,
            license: JSON.stringify(defaultSettings.license, null, 4),
            system: JSON.stringify(defaultSettings.system, null, 4)},
        onSubmit: (async ({login, password, isAdmin, license, system},{setErrors}) => {
            setSpinner(true);
            // prepare

            const systemValue = {license: JSON.parse(license), system: JSON.parse(system)}


            // save
            try {
                await bkService.userSet(storageToken,  {login, password, system:systemValue, isAdmin:!!isAdmin})
            } catch (e) {
                setSpinner(false);
                setErrors({login: e.toString()});
                return false;
            }
            setReload(true);
            setShowAdd(false);
            setSpinner(false);
            return true;
        })
    });
    useEffect(() => {
        async function userList(reload) {
            if (reload) {
                setReload(false);
                try {
                    const listUsers = await bkService.userGet(storageToken);
                    setListUsers(listUsers);
                } catch (e) {
                    setListUsers([]);
                }
            }
        }
        userList(reload);
    },[reload]);

    useEffect(() => {
        async function userData() {
            let user;
            let settings;
            try {
                user = await bkService.userMe(storageToken);
                settings = await bkService.setting(storageToken);
            } catch {
                user = false;
            }
            if (!user) {
                localStorage.removeItem("licenseToken");
                setGoAuth(true);
            }
            setUser(user);
            setDefaultSettings(settings);
        }
        userData();
    }, []);

    console.log("user", user);
    console.log("defaultSettings", defaultSettings);
    console.log("listUsers", listUsers);

    const handleDelete = async (id) => {
        setSpinner(true);
        try {
            await bkService.userDelete(storageToken,id);
        } catch (e) {

        }
        setReload(true);
        setSpinner(false);

    };
    const handleCloseAdd = () => setShowAdd(false);
    const handleSaveAdd = () => {
        formik.handleSubmit();
        //setShowAdd(false);
    };
    const handleShowAdd = () => setShowAdd(true);

    if (!storageToken) {
        return <Navigate to={"/login"}/>
    } else if (goAuth) {
        return <Navigate to={"/login"}/>
    } else if (!isObjectEmpty(user) && !user?.isAdmin) {
        return <Navigate to={"/login"}/>
    } else if (spinner) {
        return <Spinner/>
    }


    return (
        <div>
            <TopMenu user={user}/>
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>#</th>
                    <th>{t('login')}</th>
                    <th>{t('email')}</th>
                    <th>{t('isAdmin')}</th>
                    <th>{t('edit')}</th>
                </tr>
                </thead>
                <tbody>
                {listUsers?.data?.length > 0 && listUsers.data.map(v =>
                <tr key={v.id}>
                        <td>{v.id}</td>
                        <td>{v.login}</td>
                        <td>{v.email}</td>
                        <td>{v.isAdmin ? t("admin") : t("user")}</td>
                        <td>
                            <Button onClick={()=>handleDelete(v.id)} className={"m-2"} variant={"danger"} type={"button"}>{t('delete')}</Button>
                        </td>
                    </tr>
                )}
                </tbody>
            </Table>
            <Button onClick={handleShowAdd} className={"m-2"} variant={"primary"}
                    type={"button"}>{t('add new')}</Button>

            <Modal fullscreen={true} show={showAdd} onHide={handleCloseAdd}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('Add new user')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormikProvider value={formik}>
                        <form onSubmit={formik.handleSubmit}>
                            <Container>
                                <Row className={"p-2"}>
                                    <Col>
                                        <p className={"p-0 m-0"}>{t('login')}</p>
                                        <small className={"text-secondary"} dangerouslySetInnerHTML={{__html: convertPipeToNewLine(t("login_descriptions"))}} />
                                    </Col>
                                    <Col>
                                        <Field name={"login"} type={"text"}/>
                                        <ErrorMessage component={Alert} name={"login"}/>
                                    </Col>
                                </Row>
                                <Row className={"p-2"}>
                                    <Col>
                                        <p className={"p-0 m-0"}>{t('password')}</p>
                                        <small className={"text-secondary"}  dangerouslySetInnerHTML={{__html: convertPipeToNewLine(t("password_descriptions"))}} />
                                    </Col>
                                    <Col>
                                        <Field name={"password"} type={"text"}/>
                                        <ErrorMessage component={Alert} name={"password"}/>
                                    </Col>
                                </Row>
                                <Row className={"p-2"}>
                                    <Col>
                                        <p className={"p-0 m-0"}>{t('isAdmin')}</p>
                                        <small className={"text-secondary"}  dangerouslySetInnerHTML={{__html: convertPipeToNewLine(t("isAdmin_descriptions"))}} />
                                    </Col>
                                    <Col>
                                        <Field defaulvalue={0} as="select" name={"isAdmin"} >
                                            <option value={0} >{t("user")}</option>
                                            <option value={1}>{t("admin")}</option>
                                        </Field>
                                        <ErrorMessage component={Alert} name={"isAdmin"}/>
                                    </Col>
                                </Row>
                                <Row className={"p-2"}>
                                    <Col>
                                        <p className={"p-0 m-0"}>{t('license')}</p>
                                        <small className={"text-secondary"} dangerouslySetInnerHTML={{__html: convertPipeToNewLine(t("license_descriptions"))}} />
                                    </Col>
                                    <Col>
                                        <Field name={"license"}  as={"textarea"} cols={45} rows={10} />
                                        <ErrorMessage component={Alert} name={"license"}/>
                                    </Col>
                                </Row>
                                <Row className={"p-2"}>
                                    <Col>
                                        <p className={"p-0 m-0"}>{t('system')}</p>
                                        <small className={"text-secondary"} dangerouslySetInnerHTML={{__html: convertPipeToNewLine(t("system_descriptions"))}} />
                                    </Col>
                                    <Col>
                                        <Field name={"system"}  as={"textarea"} cols={45} rows={10} />
                                        <ErrorMessage component={Alert} name={"system"}/>
                                    </Col>
                                </Row>
                            </Container>
                        </form>
                    </FormikProvider>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAdd}>
                        {t('Close')}
                    </Button>
                    <Button variant="primary" onClick={handleSaveAdd}>
                        {t('Save')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );

}

import React  from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import {MainPage} from "./components/MainPage";
import {Authorization} from "./components/Authorization"
import {Logout} from "./components/Logout";
import {AdminPage} from "./components/AdminPage";

function App() {

  return (
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<MainPage/>} exact={true} />
            <Route path="/admin" element={<AdminPage/>} exact={true} />
            <Route path="/login" element={<Authorization/>} exact={true} />
            <Route path="/logout" element={<Logout/>} exact={true} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;

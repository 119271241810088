import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';

import './i18n';
import BKServiceComponent from "./contexts/BackendServiceComponent";
import BKService from "./services/Backendservice";

const bkService = new BKService();

const container = document.getElementById('root');
createRoot(container).render(
    <BKServiceComponent.Provider value={bkService}>
        <App/>
    </BKServiceComponent.Provider>
);


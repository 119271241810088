import {isObjectEmpty} from "./index";

export async function getResource(apiBase, url, method = 'GET', body = null, token = null, json = true) {
    let headers = token ? {Authorization: `Bearer ${token}`} : {};
    if (json) {
        headers['Content-Type'] = 'application/json';
        headers['Accept'] = 'application/json';
    }
    const sendBody = (body && typeof body === 'object') ? JSON.stringify(body) : body;

    const res = await fetch(`${apiBase}${url}`, {
        async: true,
        method,
        headers,
        body: sendBody ? sendBody : null
    });
    if (!res.ok) {
        if (res.status && res.status >= 400 && res.status < 500) {
            let resBody = await res.text();
            resBody = resBody ? JSON.parse(resBody) : resBody;
            throw new Error(resBody.message !== undefined ? resBody.message : 'error number: ' + res.status);
        }
        throw new Error('protocol error')
    }
    let resBody = await res.text();
    resBody = resBody ? JSON.parse(resBody) : false;
    return resBody;
}

export function appendLimits(limits, filter) {
    limits['limit'] && filter.append("limit", limits['limit']);
    limits['offset'] && filter.append("offset", limits['offset']);
    limits['order_by'] && filter.append("order_by", limits['order_by']);
    limits['order'] && filter.append("order", limits['order'].toString());
    return filter;
}

export function appendFilters(varFilter, filter) {
    if (varFilter && !isObjectEmpty(varFilter)) {
        for (const [keyFilter, valFilter] of Object.entries(varFilter)) {
            if (valFilter !== undefined)
                filter.append(keyFilter, valFilter.toString());
        }
    }
    return filter;
}

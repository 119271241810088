import compose from './compose';
import backendLanguages from "../config/backendlanguages.json";
import LanguageDetect from "languagedetect";
import siteLanguages from "../config/sitelanguages.json";
import {getResource, appendLimits, appendFilters} from "./rest-functions"


function log(...args) {
    // disable console.log in production!
    if (process.env.REACT_APP_CONSOLE_LOG !== "false") {
        console.log(args);
    }
    return null;
}
function getValue(arr, value, defaultValue = 0) {
    return arr[value] === undefined ? defaultValue: arr[value];
}

function getScale (localPercent, localTarget, localScaleFrom, localScaleTo) {
    let scalePercent = localPercent;
    let targetPercent = localTarget;
    if (localScaleFrom !== 0 || localScaleTo !== 100) {
        const rangeScale = localScaleFrom < localScaleTo ? localScaleTo - localScaleFrom : localScaleFrom - localScaleTo;
        if (localScaleFrom < localScaleTo) {
            scalePercent = localPercent > localScaleTo ? localScaleTo : localPercent < localScaleFrom ? localScaleFrom : localPercent;
            targetPercent = localTarget > localScaleTo ? localScaleTo : localTarget < localScaleFrom ? localScaleFrom : localTarget;
            scalePercent = (scalePercent - localScaleFrom) / (rangeScale / 100);
            targetPercent = (targetPercent - localScaleFrom) / (rangeScale / 100);
        } else {
            scalePercent = localPercent < localScaleTo ? localScaleTo : localPercent > localScaleFrom ? localScaleFrom : localPercent;
            targetPercent = localTarget < localScaleTo ? localScaleTo : localTarget > localScaleFrom ? localScaleFrom : localTarget;
            scalePercent = (rangeScale - (scalePercent - localScaleTo)) / (rangeScale / 100);
            targetPercent = (rangeScale - (targetPercent - localScaleTo)) / (rangeScale / 100);
        }
    }
    return [scalePercent, targetPercent]
}

function chooseColor(value, targetYellowGreen, targetYellowRed) {
    if (targetYellowRed > targetYellowGreen)
        return (value < targetYellowGreen ? 'green' :
            value > targetYellowRed ? 'red' : 'yellow');
    return (value > targetYellowGreen ? 'green' :
        value < targetYellowRed ? 'red' : 'yellow');
}

function findFirstDiff(str1, str2, len = 20) {
    const finded = [...str1].findIndex((el, index) => el !== str2[index])
    return str2.substr(finded, len) + ' ' + finded;
}

function getListByColor(data, color) {
    for (let i of data) {
        if (i['category_type'] === color)
            return i;
    }
    return null;
}

function convertJSONToObject(jsonString, defaultValue = {}) {
    if (typeof jsonString === 'object')
        return jsonString;
    try {
        return JSON.parse(jsonString);
    } catch (e) {
        return defaultValue;
    }
}

function roundWithPrecision(num, precision = 0) {
    return +(num).toFixed(precision)
}

async function convertTextToTT(token, tlService, rawWords, locale_name) {

    let words = [];
    let lemma = [];
    let tag = [];

    let res;

    try {
        res = await tlService.getTreeTager(token, locale_name, rawWords);
    } catch (e) {
        console.log(e);
        return false;
    }
    if (res && res['dataTokenizer']) {
        for (let data of res['dataTokenizer']) {
            words.push(data.word);
            lemma.push(data.lemma);
            tag.push(data.tag);
        }
        return {words, lemma, tag};
    }
    return false;
}

function isObjectEmpty(obj) {
    if (typeof obj !== 'object')
        return obj === undefined

    // noinspection LoopStatementThatDoesntLoopJS
    for (const x in obj) {
        return false;
    }
    return true;
}

function getDataFromModals(props, index) {
    const {modalData: {modal}} = props;
    return modal[index] ? modal[index].data : false;
}

function getModalFromModals(props, index) {
    const {modalData: {modal}} = props;
    return modal[index] ? modal[index] : false;
}

function createDefaultMultilangObject(values = {}) {
    return Object.keys(siteLanguages).reduce((prev, current) => {
        return {...prev, [current]: (values && values[current]) ? values[current] : ''}
    }, {})
}

function deepCopy(el) {
    return typeof el === 'object' ? JSON.parse(JSON.stringify(el)) : el;
}

function isObjectsEqual(obj1, obj2) {
    return (typeof obj1 === 'object' && typeof obj2 === 'object') ? JSON.stringify(obj1) === JSON.stringify(obj2) : obj1 === obj2;
}

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}


function removeEmptyFromObject(object) {
    Object.entries(object).map(([key, value]) => {
        if (key.length === 0 || (typeof value === 'string' && value.length === 0)) {
            delete object[key];
        }
        return false;
    })
    return object;
}

function convertLanguagesToFull(language, fullList = backendLanguages, hyphen = false) {
    if (language && fullList) {
        if (language.length === 5) return hyphen ? language.replace(/_/, "-") : language;
        const langRegexp = new RegExp("^" + language + "\\_\\w{2}$")
        const langArray = Object.keys(fullList);
        const langIndex = langArray.findIndex((element) => element.match(langRegexp));
        if (langIndex > -1) {
            return hyphen ? langArray[langIndex].replace(/_/, "-") : langArray[langIndex]
        }
    }
    return false;
}

function convertLanguagesToShort(language, fullList = backendLanguages) {
    if (language && fullList) {
        if (language.length === 2) return language;
        if (fullList[language] !== undefined) {
            return fullList[language]['shortName'];
        }
    }
    return false;
}
/*
* examples:
* checkUserRight(user,[101,103],[2,2])
* company/over company rights should always be before group right (see examples below)
* checkUserRight(user,[201,101],[0,2])
* checkUserRight(user,[308,201,101,101],[0,0,2,1])
*  */
function checkUserRight(user, right = [], user_group = []) {
    if (user.user_type_id === 1)
        return true;
    if (user?.user_rights?.length) {
        for (const tmpRight of user.user_rights) {
            const foundedIndex = right.indexOf(tmpRight.right_id);
            // forund user right in right array
            if (foundedIndex > -1) {
                // if user group id present and > 0
                if (user_group.length > foundedIndex && user_group[foundedIndex] > 0) {
                    return user_group[foundedIndex] === user.user_rights[foundedIndex].user_group_id
                } else {
                    // if not - approve
                    return true;
                }
            }
        }
    }
    return false;
}

function stripHtml(html, stripList = []) {
    if (stripList?.length) {
        return stripList.reduce((acc, tag) => acc
                .replace(new RegExp(`<\\/*${tag}[^>]*>`, "g"), '')
            , html);
    } else {
        const tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }
}

function cumulativeOffset(element) {
    let top = 0, left = 0;
    do {
        top += (element?.offsetTop || 0) - (element?.scrollTop || 0);
        left += (element?.offsetLeft || 0) - (element?.scrollLeft || 0);
        element = element.offsetParent;
    } while (element);
    return {top, left};
}

function convertPlainToHTML(input_str) {
    let text_input; //store input after beging trim()med
    let output_html = ""; //store output
    let counter;

    text_input = input_str.trim(); //trim() input
    if (text_input.length > 0) {
        output_html += "<p>"; //begin by creating paragraph
        for (counter = 0; counter < text_input.length; counter++) {
            switch (text_input[counter]) {
                case '\n':
                    if (text_input[counter + 1] === '\n') {
                        output_html += "</p>\n<p>";
                        counter++;
                    } else output_html += "<br />";
                    break;

                case ' ':
                    if (text_input[counter - 1] !== ' ' && text_input[counter - 1] !== '\t')
                        output_html += " ";
                    break;

                case '\t':
                    if (text_input[counter - 1] !== '\t')
                        output_html += " ";
                    break;

                case '&':
                    output_html += "&amp;";
                    break;

                case '"':
                    output_html += "&quot;";
                    break;

                case '>':
                    output_html += "&gt;";
                    break;

                case '<':
                    output_html += "&lt;";
                    break;

                default:
                    output_html += text_input[counter];

            }

        }
        output_html += "</p>"; //finally close paragraph
    }
    return output_html; // display output html
}

function removeBodyBorders(text) {
    return text
        .replace(/<\/pre><hr class="footer_hr_for_replacement".+?><pre>/gm, "")
        .replace(/<\/pre><hr class="header_hr_for_replacement".+?><pre>/gm, "")
        .replace(/<hr class="footer_hr_for_replacement".+?>/gm, '')
        .replace(/<hr class="header_hr_for_replacement".+?>/gm, '');
}

function removeTagMark(text , bodyBorderShow = false) {
    text = bodyBorderShow ? text : removeBodyBorders(text);
    return text.replace(/<\/mark>/gm, '').replace(/<mark [^>]+>/gm, '');
}

function setLanguage(text) {
    const lngDetector = new LanguageDetect();
    lngDetector.setLanguageType('iso2');
    const detectedLanguage = lngDetector.detect(stripHtml(text));
    if (detectedLanguage && detectedLanguage[0] !== undefined && detectedLanguage[0][0] !== undefined) {
        const lang = convertLanguagesToFull(detectedLanguage[0][0], backendLanguages);
        if (lang) {
            return lang;
        }
    }
    return false;
}

function convertArrayToText(text = []) {

    const oscillatingFirst = ["'", '"', '‹', '‘', '»', '“'];
    const oscillatingSecond = ["'", '"', '›', '‘', '«', '”'];
    let oscillatingCounter = {0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0}

    let newtext = text.map(val => val + ' ')
    newtext[newtext.length - 1] = newtext[newtext.length - 1].trimEnd();

    for (let i in text) {

        // No space BEFORE following characters: .,;!?:)]}…%‰
        if (text[i].match(/[.,;!?:)\]}…%‰]/)) {
            if (i > 0) {
                newtext[i - 1] = newtext[i - 1].trimEnd();
            }
        } else if (text[i].match(/[([{„¿¡‚†‡]/)) {
            //No space AFTER following characters: ([{„“[¿¡{‚„†‡
            if (i < newtext.length - 1) {
                newtext[i] = newtext[i].trimEnd();
            }
        }

        //oscillating
        const firstFind = oscillatingFirst.indexOf(text[i]);
        const secondFind = oscillatingSecond.indexOf(text[i]);

        if (firstFind !== -1 && oscillatingCounter[firstFind] === 0) {
            newtext[i] = newtext[i].trimEnd();
            oscillatingCounter[firstFind] = 1;
        } else if (secondFind !== -1 && oscillatingCounter[secondFind] === 1) {
            if (i > 0) {
                newtext[i - 1] = newtext[i - 1].trimEnd();
            }
            oscillatingCounter[secondFind] = 0;
        }
    }
    return newtext.join('');
}

function checkTextSize(config, full, chars) {
    if (full > config?.maxSize) {
        return "too big file size";
    }
    if (chars > config?.maxSizeInCharacters) {
        return "text too long";
    }
    return '';
}

function findToNotHTML(text, start, limit = 0, direction = -1) {
    let i = start;
    while (text[i] && text[i]['text'].match(/<.+>/)) {
        if (i === limit) return limit;
        i += direction;
    }
    return i;
}

function getPositionNotHTML(text, start, limit = 1, direction = -1) {
    let i = start;
    let counter = 0;
    while (text[i]) {
        if (text[i]['text'].match(/<.+>/))
            continue;
        counter++;
        if (counter === limit) return i;
        i += direction;
    }
    return -1;
}

// function exportTableToExcel(tableID, filename = ''){
//     var downloadLink;
//     var dataType = 'application/vnd.ms-excel';
//     var tableSelect = document.getElementById(tableID);
//     var tableHTML = tableSelect?.outerHTML?.replace(/ /g, '%20');

//     // Specify file name
//     filename = filename?filename+'.xls':'excel_data.xls';
    
//     // Create download link element
//     downloadLink = document.createElement("a");
    
//     document.body.appendChild(downloadLink);
    
//     if(navigator.msSaveOrOpenBlob){
//         var blob = new Blob(['\ufeff', tableHTML], {
//             type: dataType
//         });
//         navigator.msSaveOrOpenBlob( blob, filename);
//     }else{
//         // Create a link to the file
//         downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
    
//         // Setting the file name
//         downloadLink.download = filename;
        
//         //triggering the function
//         downloadLink.click();
//     }
// }



function downloadTextAsFile(filename, text, textType = 'text/plain') {
    const pom = document.createElement('a');
    pom.setAttribute('href', 'data:' + textType + ';charset=utf-8,' + encodeURIComponent(text));
    pom.setAttribute('download', filename);

    if (document.createEvent) {
        const event = document.createEvent('MouseEvents');
        event.initEvent('click', true, true);
        pom.dispatchEvent(event);
    } else {
        pom.click();
    }
}

function convertPipeToNewLine(text = '', html = true) {
    return html ? text.replace(/\|/g, "</br>") : text.replace(/\|/g, "\n");
}


function convertNumberToStringLocally(num, lang) {

    const convertedLanguage = convertLanguagesToFull(lang, backendLanguages, true);
    //console.log('convertNumberToStringLocally', num, lang, typeof num, convertedLanguage )
    return convertedLanguage && typeof num === "number" ?
        num.toLocaleString(convertedLanguage) : num;
}

function setAutoPosition(clientHeight, clientWidth, windowInnerHeight, windowInnerWidth, top, left) {
    let newY;
    let newX;
    if (clientHeight + top > windowInnerHeight - 80) {
        newY = top - clientHeight / 2 - 50;
    } else {
        newY = top + clientHeight / 2;
    }


    if (clientWidth + left > windowInnerWidth) {
        newX = left - clientWidth / 2;
    } else {
        newX = left + 50;
    }
    return [newX, newY]
}

function countTermsWithPositions(value = [], type = "terms") {
    if (!value || !value?.length)
        return 0;
    if (type ===  'terms')
        return value.reduce((acc, val) => acc + val?.position?.length, 0);
    if (type ===  'array')
        return value.length
    return 0
}


function textAddBodyBorders(text, resultData, offset = 0) {
    text = removeBodyBorders(text);

    if (resultData?.dataBodyRecognition) {
        const dataBodyRecognition = resultData.dataBodyRecognition;
        if (dataBodyRecognition[1] > -1) {
            text = text.substring(0, dataBodyRecognition[1] + offset) + `<hr class="footer_hr_for_replacement" />` + text.substring(dataBodyRecognition[1] + offset);
        }
        if (dataBodyRecognition[0] > -1) {
            text = text.substring(0, dataBodyRecognition[0]) + `<hr class="header_hr_for_replacement" />` + text.substring(dataBodyRecognition[0]);
        }
    }
    return text;
}

function arrayAddBodyBorders(splittedText, resultData) {
    if (resultData?.dataBodyRecognition) {
        const dataBodyRecognition = resultData.dataBodyRecognition;
        if (dataBodyRecognition[1] > -1) {
            splittedText[splittedText.length -1].post =  `<hr class="footer_hr_for_replacement" />` + splittedText[splittedText.length -1].post;
        }
        if (dataBodyRecognition[0] > -1) {
            splittedText[0].pre += `<hr class="header_hr_for_replacement" />`;
        }
    }
    return splittedText;
}


export {
    getValue,
    getScale,
    removeBodyBorders,
    textAddBodyBorders,
    arrayAddBodyBorders,
    countTermsWithPositions,
    convertNumberToStringLocally,
    convertPipeToNewLine,
    getResource, appendLimits, appendFilters,
    isObjectsEqual,
    chooseColor,
    findFirstDiff,
    checkTextSize,
    stripHtml,
    compose,
    checkUserRight,
    convertLanguagesToFull,
    convertPlainToHTML,
    convertLanguagesToShort,
    removeTagMark,
    setLanguage,
    cumulativeOffset,
    onlyUnique,
    deepCopy,
    convertArrayToText,
    createDefaultMultilangObject,
    getDataFromModals,
    getModalFromModals,
    convertTextToTT,
    removeEmptyFromObject,
    log,
    isObjectEmpty,
    roundWithPrecision,
    convertJSONToObject,
    getListByColor,
    findToNotHTML,
    getPositionNotHTML,
    downloadTextAsFile,
    setAutoPosition
}


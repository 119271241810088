import React  from 'react';
import {Col, Container, Nav, Navbar, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";

export function TopMenu({user}) {
    const {t} = useTranslation();
    const location = useLocation();
    return(
        <Navbar bg="primary" variant="dark">
            <Container>
                <Navbar.Brand href="/">{t('SiteName')}</Navbar.Brand>
                <Nav className="me-auto">
                    <Nav.Link active={location?.pathname === "/"} href="/">{t('UserPage')}</Nav.Link>
                    {user?.isAdmin && <Nav.Link active={location?.pathname === "/admin"} href="/admin">{t('AdminPage')}</Nav.Link>}
                    <Nav.Link href="/logout">{t('Logout')}</Nav.Link>
                </Nav>
                <Row>
                    <Col className={"ms-auto text-end"}>{user?.login}</Col>
                </Row>
            </Container>
        </Navbar>
    );
}
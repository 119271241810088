import React from 'react';
import {FormikProvider} from "formik";
import {Collapse, Container} from "react-bootstrap";

export function Logging({formik, section, componentData, getRow}) {

    return (
        <FormikProvider value={formik}>
            <Container fluid>
                {getRow(section, "type", "select",componentData[section][0][2])}
                {getRow(section, "minLevel", "select",componentData[section][4][2])}
                {getRow(section, "rotationTime", "text")}
                {getRow(section, "msgFormat", "text")}
                {getRow(section, "timestampFormat", "text")}
                {getRow(section, "logPassword", "text")}
                <Collapse in={formik.values["logging_type"] === "file"}>
                    <Container fluid>
                        {componentData[section].map(v => ["logPassword","rotationTime","type", "minLevel", "msgFormat", "timestampFormat"].indexOf(v[0]) === -1 ? getRow(section, v[0], v[1], v[2]) : null)}
                    </Container>

                </Collapse>

            </Container>
        </FormikProvider>
    );
}
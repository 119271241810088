import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {roundWithPrecision} from "../../utils";

const arrayValues = ["enableServiceFunction", "enabledCompanyLicense", "enabledCompanyIDs",
    "enableLibrares", "enabledUserTypes", "enabledUserLicense", "trustedIP"];

export function LicenseInfo({license}) {
    const {t} = useTranslation();

    return (
        <Container fluid>
            <Row className={"p-2"}>
                <Col>
                    <p className={"p-0 m-0"}>{t("userInfo")}</p>
                </Col>
                <Col>
                    <strong>{JSON.stringify(license?.userInfo)}</strong>
                </Col>
            </Row>
            <Row className={"p-2"}>
                <Col>
                    <p className={"p-0 m-0"}>{t("maxRequestSize")}</p>
                    <small className={"text-secondary"}>{t("maxRequestSize_descriptions")}</small>
                </Col>
                <Col>
                    <strong>{license?.maxRequestSize > 0 ? roundWithPrecision(license.maxRequestSize / (1024 * 1024), 1) + " MB" : t("unlimited")}</strong>
                </Col>
            </Row>
            <Row className={"p-2"}>
                <Col>
                    <p className={"p-0 m-0"}>{t("maximumUsers")}</p>
                    <small className={"text-secondary"}>{t("maximumUsers_descriptions")}</small>
                </Col>
                <Col>
                    <strong>{license?.maximumUsers > 0 ? license.maximumUsers : t("unlimited")}</strong>
                </Col>
            </Row>
            <Row className={"p-2"}>
                <Col>
                    <p className={"p-0 m-0"}>{t("validFrom")}</p>
                    <small className={"text-secondary"}>{t("validFrom_descriptions")}</small>
                </Col>
                <Col>
                    <strong>{license?.validFrom?.length ? license.validFrom : t("from any time")}</strong>
                </Col>
            </Row>

            <Row className={"p-2"}>
                <Col>
                    <p className={"p-0 m-0"}>{t("validTo")}</p>
                    <small className={"text-secondary"}>{t("validTo_descriptions")}</small>
                </Col>
                <Col>
                    <strong>{license?.validTo?.length ? license.validTo : t("unlimited")}</strong>
                </Col>
            </Row>

            <Row className={"p-2"}>
                <Col>
                    <p className={"p-0 m-0"}>{t("maxAnalizeTextSize")}</p>
                    <small className={"text-secondary"}>{t("maxAnalizeTextSize_descriptions")}</small>
                </Col>
                <Col>
                    <strong>{license?.maxAnalizeTextSize > 0 ? license.maxAnalizeTextSize + " " + t("symbols") : t("unlimited")}</strong>

                </Col>
            </Row>
            <Row className={"p-2"}>
                <Col>
                    <p className={"p-0 m-0"}>{t("floatLoginTimeout")}</p>
                    <small className={"text-secondary"}>{t("floatLoginTimeout_descriptions")}</small>
                </Col>
                <Col>
                    <strong>{license?.floatLoginTimeout > 0 ? license.floatLoginTimeout + " " + t("msec") : t("unlimited")}</strong>
                </Col>
            </Row>
            <Row className={"p-2"}>
                <Col>
                    <p className={"p-0 m-0"}>{t("floatLoginMaxUsers")}</p>
                    <small className={"text-secondary"}>{t("floatLoginMaxUsers_descriptions")}</small>
                </Col>
                <Col>
                    <strong>{license?.floatLoginMaxUsers > 0 ? license.floatLoginMaxUsers + " " + t("users") : t("unlimited")}</strong>

                </Col>
            </Row>
            <Row className={"p-2"}>
                <Col>
                    <p className={"p-0 m-0"}>{t("anonymizeLogs")}</p>
                    <small className={"text-secondary"}>{t("anonymizeLogs_descriptions")}</small>
                </Col>
                <Col>
                    <strong>{!!license?.anonymizeLogs ? "enabled" : "disabled"}</strong>
                </Col>
            </Row>
            {arrayValues.map(value =>
                <Row key={value} className={"p-2"}>
                    <Col>
                        <p className={"p-0 m-0"}>{t(value)}</p>
                        <small className={"text-secondary"}>{t(value + "_descriptions")}</small>
                    </Col>
                    <Col>
                        {license[value]?.length ? license[value]
                                .map((v, i) => <strong key={i}
                                                       className={"p-0 m-0"}> {v}{i < license[value].length - 1 && ","} </strong>) :
                            <strong className={"p-0 m-0"}>{t("all")}</strong>}
                    </Col>
                </Row>)}

            <Row className={"p-2"}>
                <Col>
                    <p className={"p-0 m-0"}>{t("openapiPaths")}</p>
                    <small className={"text-secondary"}>{t("openapiPaths_descriptions")}</small>
                </Col>
                <Col>
                    {license["openapiPaths"].length ? license["openapiPaths"]
                            .map((v, i) => <p key={i} className={"p-0 m-0"}> {JSON.stringify(v)} </p>) :
                        <strong className={"p-0 m-0"}>{t("all")}</strong>}
                </Col>
            </Row>

        </Container>
    )
}